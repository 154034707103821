import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://backoffice-ecgz.onrender.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Infura',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/9bb0768d-9662-48da-8291-9dd17d494c00/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#FF5833',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fffff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#fffff',
};
